import React from 'react'
import Layout from '../components/Layout'
import Helmet from 'react-helmet'
import Contact from '../components/Contact';

const ContactPage = () => {
    return (
        <Layout>
            <Helmet
                title="Contactez-nous"
                meta={[
                    {
                        name: "description",
                        content: "Que ça soit par email, téléphone ou votre présence Akka media est toujours la pour vous servire contactez-nous"
                    }
                ]}
            />
            <Contact />
        </Layout>
    )
}
export default ContactPage