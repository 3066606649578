import React from 'react'
import styles from './contact.module.css'
import ContactForm from '../Comon/ContactForm'
import GoogleMap from '../Comon/GoogleMap'
import { Icon } from 'semantic-ui-react';
const Contact = () => {
    return (
        <div>
            <section className={`header-parallax ${styles.hero}`}>
                <div className="container">
                    <h1>Contactez-nous</h1>
                </div>
            </section>
            <section className={`container ${styles.contact_section}`}>
                <div className="ui stackable grid">
                    <div className={`nine wide column ${styles.contact_form}`}>
                        <ContactForm />
                    </div>
                    <div className={`seven wide column ${styles.contact_info}`}>
                        <h1>Nos Coordonnées</h1>
                        <h3><a href="https://maps.google.com/?ll=36.74276809411669,3.030017023092796" target="_blank" rel="noopener noreferrer"><Icon size="large" name="map marker alternate" />Cité Petit Bois, 23 Bloc C Paradou–Hydra. Algers</a></h3>
                        <h3><a href="tel:+21323482673"><Icon size="large" name="phone" />00 213 (0) 23 482 673</a></h3>
                        <h3><a href="tel:+213550644602"><Icon size="large" name="mobile alternate" />00 213 (0) 550 644 602</a></h3>
                        <h3><a href="mailto:akkacasting@gmail.com"><Icon size="large" name="envelope outline" />akkacasting@gmail.com</a></h3>
                    </div>
                </div>
            </section>
            <GoogleMap height={500} />
        </div>
    )
}
export default Contact