import React from 'react'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps'
const GoogleMaps = ({ height }) => {
  const position = { lat: 36.74276809411669, lng: 3.030017023092796 }
  const MapWithAMarker = withScriptjs(
    withGoogleMap(props => (
      <GoogleMap
        defaultZoom={15}
        defaultCenter={{ lat: position.lat, lng: position.lng }}
      >
        <Marker position={{ lat: position.lat, lng: position.lng }} />
      </GoogleMap>
    ))
  )
  return (
    <MapWithAMarker
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBBaTWG9lPQ4zidxNDHGeBKMFfpfxZryRo&v=3.exp&libraries=geometry,drawing,places"
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: height }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  )
}
export default GoogleMaps
